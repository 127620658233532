<template>
  <div class="previewContainer">
    <div class="loading" v-if="!previewItem">
      <div class="lds-hourglass"></div>
    </div>
    <section v-if="previewItem" class="itemDisplay">
      <!--  -->
      <div class="itemImgageBox">
        <!-- preview image -->
        <div class="dispayImgContainer">
          <img @click="toggleLargePreview" :src="previewImg" alt />
        </div>
        <!-- alternative images -->
        <div class="alternativeImages">
          <i @click="prevPic" class="fa-solid fa-angle-left"></i>
          <div v-for="item in MinAltImgs" :key="item">
            <img @click="changeDisplayPic(item)" :src="item" alt />
          </div>
          <i @click="nextPic" class="fa-solid fa-angle-right"></i>
        </div>
      </div>
      <!--  -->
      <div class="aboutItem">
        <h3>{{ previewItem.name }}</h3>
        <!--  -->
        <div class="desktopExtaInfo">
          <div class="extraInfo__desc">
            <!-- <h4>Product description</h4> -->
            <p v-if="previewItem">{{ previewItem.description }}</p>
            <div class="materials">
              <label for="materials">materials</label>
              <ul v-if="previewItem">
                <li v-for="material in previewItem.materials" :key="material">{{ material }}</li>
              </ul>
            </div>
            <div class="washing">
              <label for="washing">washing instruction</label>

              <ul v-if="previewItem">
                <li v-for="washing in previewItem.washing" :key="washing">{{ washing }}</li>
              </ul>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="price_Qty">
          <h2>${{ parseFloat(price).toFixed(2) }}</h2>
          <div class="increaseQty">
            <div class="icons">
              <i @click="remQty" class="fa-solid fa-minus"></i>
              <!-- <input type="text" name id value="0" /> -->
              <input type="number" :value="quantity" min="1" max="10" />
              <i @click="addQty" class="fa-solid fa-plus"></i>
            </div>
          </div>
        </div>
        <!--  -->
        <form>
          <div class="selections">
            <select v-model="selectedSize">
              <option value disabled>select size</option>

              <option
                class="selections"
                v-for="size in previewItem.sizes"
                :key="size"
                name="size"
                id="size"
                :value="size"
              >{{ size }}</option>
            </select>
            <br />
            <select name="style" id="style" v-model="selectedColor">
              <option disabled value>select color</option>

              <option
                class="selections"
                v-for="color in previewItem.pickColor"
                :key="color"
                name="color"
                id="color"
                :value="color"
              >{{ color }}</option>
            </select>
          </div>
        </form>
        <div class="addToCartContainer">
          <div class="addToCartContainer_errorBox">
            <p v-if="errMessage">{{errMessage}}</p>
          </div>
          <div class="addToCartContainer_buttonBox">
            <button v-if="!addedToCart" class="addtoCart" @click="addItemToCart">add to cart</button>
            <p class="addedNote" v-if="addedToCart">
              <router-link to="/cart">
                item added to cart:go to cart
                <i class="fa-solid fa-cart-shopping"></i>
              </router-link>
            </p>
          </div>
        </div>
        <!--  -->
      </div>
      <!--  -->
    </section>

    <!-- large preview  -->
    <section v-if="largePreview" class="largePreview">
      <img :src="previewImg" alt />
    </section>
    <!--  -->

    <section class="SmallScreenextaInfo">
      <div class="extraInfo__desc">
        <h4>Product description</h4>
        <p v-if="previewItem">{{ previewItem.description }}</p>
        <div class="materials">
          <label for="materials">
            <b>materials</b>
          </label>
          <ul v-if="previewItem">
            <li v-for="material in previewItem.materials" :key="material">{{ material }}</li>
          </ul>
        </div>
        <div class="washing">
          <label for="washing">
            <b>washing instruction</b>
          </label>

          <ul v-if="previewItem">
            <li v-for="washing in previewItem.washing" :key="washing">{{ washing }}</li>
          </ul>
        </div>
      </div>
    </section>
    <section class="productReccomendation">
      <div>
        <trending-comp :arrdata="trendingArr" heading="similar items"></trending-comp>
      </div>
    </section>
  </div>
</template>

<script>
import TrendingComp from "../components/TrendingComp";
import trendingData from "../State/data/trendingData";

export default {
  components: {
    TrendingComp
  },
  data() {
    return {
      previewImg: "",
      altImages: [],
      MinAltImgs: [],
      selectionTab: "desc",
      quantity: 1,
      largePreview: false,
      addedToCart: false,
      price: 0,
      selectedColor: "",
      selectedSize: "",
      altImgIndex: 0,
      errMessage: "",
      trendingArr: [],
      DesktprevImg: "https://img.imageboss.me/alimupic/cover/600x800/"
    };
  },
  created() {
    this.trendingArr = trendingData;

    window.addEventListener("scroll", this.onScroll);
    scroll(0, 0);
  },
  mounted() {
    const itemCode = this.$route.params;
    this.$store.dispatch("shop/getPreviewItem", itemCode.id);

    this.previewImg = this.DesktprevImg + this.previewItem.imgLink;
    console.log(this.previewImg);
    console.log("link", this.previewItem.imgLink);

    // loop thorugh olternative images and add image boss url and image dimensions

    //display 3 images at a time

    for (let i = 0; i < this.previewItem.altImages.length; i++) {
      this.altImages.push(
        "https://img.imageboss.me/alimupic/cover/100x100/" +
          this.previewItem.altImages[i]
      );
    }
    console.log("alternative", this.altImages);

    this.MinAltImgs = this.altImages.slice(
      this.altImgIndex,
      this.altImgIndex + 3
    );

    this.price = this.previewItem.price;
    // console.log(this.previewItem.price);
  },
  computed: {
    previewItem() {
      return this.$store.getters["shop/getPreviewItem"];
    }
  },
  methods: {
    onScroll() {
      let num = window.scrollY;
      // console.log(num);

      if (num > 20) {
        this.largePreview = false;
      }
    },
    changeDisplayPic(item) {
      this.previewImg =
        this.DesktprevImg + item.split("/")[6] + "/" + item.split("/")[7];

      console.log("/" + item.split("/")[6] + "/" + item.split("/")[7]);

      // this.altImages = this.previewItem.altImages.filter((x, i) => {
      //   if (x === item) {
      //     console.log(i);

      //     return i;
      //   }
      // });
      // this.altImages.push(this.previewItem.imgLink);
      // this.altImages = this.previewItem.altImages.slice(0, 3);
    },
    descriptionStat(val) {
      this.selectionTab = val;
    },
    addItemToCart() {
      const sku = this.skuGene();
      // console.log("sku value", sku);
      this.previewItem.selectedColor = this.selectedColor;
      this.previewItem.selectedSize = this.selectedSize;
      this.previewItem.sku = sku;
      if (this.previewItem.selectedColor && this.previewItem.selectedSize) {
        this.$store.dispatch("shop/addToCartx", this.previewItem);

        // console.log(this.selectedSize);
        // console.log(this.selectedColor);

        this.addedToCart = true;
        this.errMessage = "";
      } else {
        if (!this.previewItem.selectedSize) {
          this.errMessage = "please select your size";
        } else if (!this.previewItem.selectedColor) {
          this.errMessage = "please select color";
        }
      }
    },
    nextPic() {
      const totalImg = this.altImages.length;

      this.altImgIndex += 1;

      if (this.altImgIndex <= totalImg - 3) {
        this.MinAltImgs = this.altImages.slice(
          this.altImgIndex,
          this.altImgIndex + 3
        );
      } else {
        this.altImgIndex = totalImg - 3;
      }
      // console.log("index", this.altImgIndex);
    },
    prevPic() {
      // const totalImg = this.previewItem.altImages.length;

      this.altImgIndex -= 1;
      if (this.altImgIndex >= 0) {
        this.MinAltImgs = this.altImages.slice(
          this.altImgIndex,
          this.altImgIndex + 3
        );
      } else {
        this.altImgIndex = 0;
      }
      // console.log("index", this.altImgIndex);
    },

    toggleLargePreview() {
      // console.log("hi");
      scroll(0, 0);

      this.largePreview == false
        ? (this.largePreview = true)
        : (this.largePreview = false);
    },
    addQty() {
      if (this.quantity < 10) {
        this.quantity += 1;
        this.previewItem.quantity = this.quantity;
        this.price = (this.previewItem.price * this.quantity).toFixed(2);
      }
    },
    remQty() {
      if (this.quantity > 1) {
        this.quantity -= 1;
        this.previewItem.quantity = this.quantity;
        this.price = (this.previewItem.price * this.quantity).toFixed(2);
      }
    },
    skuGene() {
      let result = [];
      let hexRef = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z"
      ];

      for (let n = 0; n < 8; n++) {
        result.push(hexRef[Math.floor(Math.random() * 16)]);
      }
      const sku = result.join("");
      return sku;
    }
  }
};
</script>


<style lang="scss" scoped>
.itemDisplay {
  display: flex;
  box-sizing: border-box;
  height: 92vh;
  padding: 0px 10%;
  background-color: rgb(234, 234, 234);
  @media (min-width: 1024px) and(max-width: 1080px) {
    padding: 0 5%;
  }
  @media (min-width: 650px) and (max-width: 768px) {
    //tablet
    padding: 10px 4%;
  }
  @media (max-width: 650px) {
    flex-direction: column;
    height: 100%;
    padding: 0;
  }
  .itemImgageBox {
    height: 100%;
    flex: 0 0 40%;
    position: relative;
    background-color: rgb(233, 233, 233);
    display: grid;
    grid-template-rows: 90% auto;
    @media (min-width: 650px) and (max-width: 768px) {
      //tablet
      flex: 0 0 50%;
    }
    @media (max-width: 650px) {
    }
    .dispayImgContainer {
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .alternativeImages {
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      gap: 5px;
      cursor: pointer;

      i {
        color: #ececec;
        background-color: rgb(0, 0, 0);
        padding: 10px;
        font-size: 30px;
        height: 30px;

        &:active {
          background-color: rgb(218, 218, 218);
        }
      }
      .fa-angle-left {
        position: relative;
      }
      div {
        display: flex;
        width: 50px;
        height: 50px;
        border: solid 3px rgba(0, 0, 0, 0.479);
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  //

  .aboutItem {
    padding: 0 5%;
    background-color: rgb(245, 245, 245);
    height: 90%;
    flex: 0 1 60%;
    @media (min-width: 650px) and (max-width: 768px) {
      //tablet
      flex: 0 1 50%;
    }

    .desktopExtaInfo {
      @media (max-width: 769px) {
        display: none;
      }
    }

    p {
      font-family: "Roboto", sans-serif;
    }
    label {
      font-size: 18px;
      font-weight: 500;
    }

    label,
    ul,
    h3,
    h2,
    h4 {
      font-family: "Roboto", sans-serif;
    }

    h3 {
      // margin: 10px 0;
      font-size: 30px;
      text-transform: capitalize;
      font-weight: 400;
    }

    h4 {
      text-align: justify;
    }

    .price_Qty {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // margin-bottom: 20px;

      h2 {
        font-size: 30px;
        flex: 0 0 20%;
      }

      // padding: 0 5%;

      .increaseQty {
        flex: 0 0 50%;
        margin: 0;
        padding: 0 5px;
        box-sizing: border-box;
        width: 100%;
        // transform: translate(0, 20%);
        // background-color: rgb(228, 228, 228);
        .icons {
          margin: 0px 0;
          display: flex;
          gap: 2px;
          justify-content: center;
          align-items: center;
          // background-color: #545554;

          i {
            font-size: 25px;
            padding: 0 2px;
            background-color: black;

            &:active {
              background-color: rgb(218, 218, 218);
            }
          }

          .fa-minus {
            color: white;
          }
          .fa-plus {
            color: white;
          }
          input {
            width: 50px;
            text-align: center;
            padding: 3.5px 0;
          }
        }
      }
    }
  }

  form {
    // padding: 0 10%;
    box-sizing: border-box;
    .selections {
      display: flex;
      gap: 10px;
      select {
        width: 100%;
        padding: 5px;
        margin: 2px 0;
        background-color: white;
        color: black;
        border-radius: none;
      }
    }
  }

  .addToCartContainer {
    // margin-bottom: 50px;
    position: relative;
    &_errorBox {
      position: relative;
      height: 10px;
      margin: 10px;
      display: flex;
      justify-content: center;
      p {
        position: absolute;
        color: rgb(212, 53, 53);
        margin: 0;
        text-align: center;
      }
    }
    &_buttonBox {
      .addtoCart {
        border-radius: 0;
        width: 100%;
        padding: 10px;
        background-color: #000000;
        color: white;
      }
    }
  }
}

.SmallScreenextaInfo {
  min-height: 60vh;
  padding: 10px 5%;
  background-color: white;
  @media (min-width: 769px) {
    display: none;
  }

  h4 {
    text-transform: capitalize;
    font-size: 20px;
  }
  &__desc {
    .materials {
      ul {
        list-style-type: armenian;
      }
    }
  }
}
//end of animation
</style>