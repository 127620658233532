<template>
  <div class="checkoutContainer">
    <div class="summaryContainer">
      <div class="summaryContainer__summary">
        <h2>Checkout Summary</h2>
        <div class="subtotal">
          <p>subtotal:</p>
          <p>${{cartTotalPrice}}</p>
        </div>
        <div class="shipping">
          <p>shipping:</p>
          <p v-if="shippingCost >0">${{shippingCost}}</p>
          <p else v-if="shippingCost <=0">Standard</p>
          <p else v-if="shippingCost >= 100">Free</p>
        </div>
        <!-- <div class="discountprice">
          <p>discount:</p>
          <p>{{discountpct}}</p>
        </div>-->
        <div class="total">
          <p>TOTAL:</p>
          <p>AUD ${{ checkoutTotal }}</p>
          <!-- <p>shippping: 10.95</p> -->
        </div>
        <div class="discount">
          <input type="text" placeholder="apply discount" />
          <button>apply</button>
        </div>

        <div></div>
      </div>
      <div class="summaryContainer__payButtonCont">
        <div class="paypalButton" v-if="paypalbuttonShow">
          <div id="paypal-button-container"></div>
        </div>
      </div>
    </div>

    <div class="previewContainer">
      <!-- <h2>My bag</h2> -->
      <div class="items" v-for="item in getCartItems" :key="item.sku">
        <div class="items__image">
          <img :src="desktop+item.imgLink" alt />
        </div>
        <ul class="items__desc">
          <li>
            <b>{{ item.name }}</b>
            <i @click="removeItem(item.sku)" class="fa-solid fa-trash-can"></i>
          </li>
          <hr />
          <li>AUD ${{ (item.price * item.quantity).toFixed(2) }}</li>
          <li>Qty: {{ item.quantity }}</li>
          <li>size: {{ item.selectedSize }}</li>
          <li>color: {{ item.selectedColor }}</li>
          <!-- <li>remove</li> -->
        </ul>
      </div>
    </div>
    <div class="addOns">
      <trending-comp :arrdata="trendingArr" heading="add on"></trending-comp>
    </div>

    <!-- <p style=" display:flex;flex-wrap:wrap">{{retrieveAccessToken}}</p> -->
  </div>
  <div class="addOnDE">
    <trending-comp :arrdata="trendingArr" heading="add on"></trending-comp>
  </div>
</template>


<script>
import TrendingComp from "../components/TrendingComp";
import trendingData from "../State/data/trendingData";

export default {
  components: {
    TrendingComp
  },
  data() {
    return {
      // getCartItems: [{}]
      // cartTotalPrice: 0,
      //app payment
      desktop: "https://img.imageboss.me/alimupic/cover/400x200/",
      accessToken: "",
      mydata: {},
      removeButton: true,
      cartTotalPrice: 0,
      paypalbuttonShow: true,
      trendingArr: [],
      shippingCost: 10.95,
      checkoutTotal: 0
      // discountpct: 0.01
    };
  },
  created() {
    this.trendingArr = trendingData;
    this.$store.dispatch("shop/retrieveToken");
  },
  mounted() {
    this.getAccessToken();
    scroll(0, 0);

    // this.getPaypal();
  },
  computed: {
    getCartItems() {
      return this.$store.getters["shop/getCartItem"];
    },
    retrieveAccessToken() {
      return this.$store.getters["shop/getAccessToken"];
    }
  },
  methods: {
    removeItem(sku) {
      this.$store.dispatch("shop/deleteItem", sku);

      this.paypalbuttonShow = false;
      setTimeout(() => {
        this.getItemObject();
        this.paypalbuttonShow = true;
        setTimeout(() => {
          this.getPaypal();
        }, 1000);
      }, 1000);
    },
    getAccessToken() {
      const cookiesActive = this.retrieveAccessToken;
      if (!cookiesActive || cookiesActive == "undefined") {
        this.$store.dispatch("shop/renewToken");
        this.$store.dispatch("shop/retrieveToken");
      }
      if (!cookiesActive) {
        setTimeout(() => {
          // console.log("got", this.retrieveAccessToken);
          this.getPaypal();
        }, 2000);
      } else {
        this.getPaypal();
        // console.log("here");
      }
    },
    getPaypal() {
      const mytoken = JSON.parse(this.retrieveAccessToken);
      let myItems;
      let mydata;

      setTimeout(() => {
        myItems = this.getItemObject();

        mydata = JSON.stringify({
          token: mytoken,
          items: myItems
        });

        // console.log(mytoken);
        // console.log(myItems);
        // call paypal here
        // this.getPaypal(mydata, uid);
      }, 3000);

      window.paypal
        .Buttons({
          // Order is created on the server and the order id is returned
          createOrder: () => {
            return fetch(
              // "https://alimuonline22.herokuapp.com/my-server/create-order",
              "https://alimuonline22.herokuapp.com/my-server/create-order",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json"
                },
                body: mydata
                // use the "body" param to optionally pass additional order information
                // like product ids or amount
              }
            )
              .then(response => response.json())
              .then(order => order.id);
          },
          // Finalize the transaction on the server after payer approval
          onApprove: data => {
            return fetch(
              // "https://alimuonline22.herokuapp.com/my-server/capture-order/" +
              //   data.orderID,
              //
              "https://alimuonline22.herokuapp.com/my-server/capture-order/" +
                data.orderID,
              {
                method: "POST",
                body: this.retrieveAccessToken
              }
            )
              .then(response => response.json())
              .then(orderData => {
                // Successful capture! For dev/demo purposes:
                // console.log("Capture result", JSON.parse(orderData.result));
                // send email here  // add size and color to recipt
                // fetch
                //  mydata = JSON.stringify({
                //     token: mytoken,
                //     items: myItems
                //   });
                const recipt = JSON.parse(orderData.result);
                //
                // console.log(myItems);
                // console.log(this.retrieveAccessToken);

                let payload = {
                  item: myItems,
                  reciptid: recipt.id,
                  token: this.retrieveAccessToken
                };

                // console.log(myItems);

                this.$store.dispatch("shop/getRecipt", payload);

                this.$router.push("/thankYou!");

                //  const x = localStorage.getItem("myCart");
                this.$store.dispatch("shop/clearCart");
                // this.$store.dispatch

                // const transaction =
                // orderData.purchase_units[0].payments.captures[0];
                // alert(orderData.result.status);
                // When ready to go live, remove the alert and show a success message within this page. For example:
                // const element = document.getElementById('paypal-button-container');
                // element.innerHTML = '<h3>Thank you for your payment!</h3>';
                // Or go to another URL:  actions.redirect('thank_you.html');
              });
          }
        })
        .render("#paypal-button-container");
    },
    getItemObject() {
      let paypalItems = null;
      this.cartTotalPrice = 0;
      // console.log(this.getCartItems);
      for (let i = 0; i < this.getCartItems.length; i++) {
        // console.log(cartTotalPrice);

        if (!paypalItems) {
          paypalItems = [
            {
              name: this.getCartItems[i].name,
              // "second Product Name" /* Shows within upper-right dropdown during payment approval */,
              description: this.getCartItems[i].name,
              sku: this.getCartItems[i].sku,
              sizes: this.getCartItems[i].selectedSize,
              colors: this.getCartItems[i].selectedColor,
              // "Optional descriptive text.." /* Item details will also be in the completed paypal.com transaction view */,
              unit_amount: {
                currency_code: "AUD",
                value: this.getCartItems[i].price
              },
              quantity: this.getCartItems[i].quantity
            }
          ];
        } else {
          // console.log("theresss");
          paypalItems.push({
            name: this.getCartItems[i].name, // "second Product Name" /* Shows within upper-right dropdown during payment approval */,
            description: this.getCartItems[i].name, // "Optional descriptive text.." /* Item details will also be in the completed paypal.com transaction view */,
            sku: this.getCartItems[i].sku,
            sizes: this.getCartItems[i].selectedSize,
            colors: this.getCartItems[i].selectedColor,

            unit_amount: {
              currency_code: "AUD",
              value: this.getCartItems[i].price
            },
            quantity: this.getCartItems[i].quantity
          });
        }
        this.cartTotalPrice += parseFloat(
          this.getCartItems[i].price * this.getCartItems[i].quantity
        );
      }

      //
      this.cartTotalPrice = this.cartTotalPrice.toFixed(2);
      // console.log("final total ", this.getTotalPrice);

      if (this.cartTotalPrice == 0) {
        this.shippingCost = 0;
      } else if (this.cartTotalPrice > 150) {
        this.shippingCost = 0;
      }
      // console.log("final total ", this.getTotalPrice);
      // this.shippingCost = 10.95;

      this.checkoutTotal = (parseFloat(this.cartTotalPrice) + this.shippingCost)
        // this.discountpct
        .toFixed(2);

      // console.log(this.checkoutTotal);

      // console.log("final total + shipping", this.getTotalPrice);

      let checkout = {
        items: paypalItems,
        cartTotalPrice: this.cartTotalPrice,
        shipping: this.shippingCost,
        finalTotal: this.checkoutTotal
        // discount: this.discountpct
      };

      // console.log("final checkout object", checkout);
      return checkout;
      //
    },
    checkCart() {}
  }
};
</script>



<style lang="scss" scoped>
.checkoutContainer {
  min-height: 100vh;
  h2 {
    background-color: rgba(0, 0, 0, 0.678);
    color: rgb(255, 255, 255);
    padding: 10px;
    margin: 0;
    text-align: right;
  }

  @media (min-width: 1000px) {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 20px 10%;
  }

  @media (min-width: 1024px) and(max-width: 1080px) {
    padding: 10px 5%;
  }

  .summaryContainer {
    padding: 20px 5%;

    @media (min-width: 1000px) {
      padding: 0;
      flex: 0 0 40%;
      height: 50vh;
      border-radius: 5px;
      position: sticky;
      top: 0px;
    }
    &__summary {
      .subtotal {
        display: flex;
        justify-content: space-between;
        @media (min-width: 1000px) {
          margin-top: 20px;
        }

        p {
          font-size: 25px;
          margin: 0px 0;
          text-transform: capitalize;
        }
      }
      .shipping {
        display: flex;
        justify-content: space-between;
        p {
          font-size: 18px;
          margin: 5px 0 15px 0px;
          // margin: 0 0 0 5px;
          color: rgb(71, 71, 71);
        }
      }
      .discountprice {
        display: flex;
        justify-content: space-between;
        p {
          font-size: 18px;
          // margin: 5px 0 15px 0;
          margin: 5px 0 15px 5px;

          color: rgb(71, 71, 71);
        }
      }
      .total {
        display: flex;
        justify-content: space-between;
        p {
          font-size: 25px;
          margin: 0;

          &:nth-child(2) {
            font-weight: 800;
            border-bottom: solid 2px black;
          }
        }
      }
      .discount {
        position: relative;
        margin: 10px 0;
        height: 60px;
        button {
          position: absolute;
          width: 50px;
          top: 40px;
          right: 0;
        }
        input {
          position: relative;
          box-sizing: border-box;
          width: 100%;
          margin: 5px 0;
          padding: 5px;
          text-align: right;
          &::placeholder {
            text-align: right;
            padding: 0 5px;
          }
        }
      }
    }
    &__payButtonCont {
      position: relative;
      min-height: 100px;
      // background-color: pink;
      overflow: auto;
      height: auto;
      max-height: 120%;

      .paypalButton {
        position: relative;
        // right: 0;
        // top: 20px;
        width: 100%;
        z-index: 0;
      }
    }
  }

  .previewContainer {
    @media (min-width: 1000px) {
      padding: 0px;
      flex: 0 1 60%;
    }
    h2 {
      // margin: 10px 5%;
      // border-bottom: solid 2px #839285;
      // margin: 20px 10px 0 12px;
      padding: 2px 0px;
      display: inline-block;
      font-weight: 100;
      color: #021605;
      margin: 20px 0 0 10px;
    }
    .items {
      padding: 10px 5%;
      display: flex;
      justify-content: space-between;
      // border-bottom: solid 2px rgb(224, 224, 224);

      @media (min-width: 1000px) {
        padding: 10px;
      }
      &:nth-child(odd) {
        background-color: #e9e9e93f;
        border-radius: 5px;
      }
      &__image {
        flex: 0 0 40%;
        width: 100%;
        height: 150px;
        @media (min-width: 1000px) {
          // padding: 10px;   img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &__desc {
        flex: 0 0 50%;
        width: 100%;
        height: 100%;
        // background-color: rgb(80, 19, 19);
        margin: 0;

        list-style-type: none;

        li {
          font-size: 20px;
          position: relative;
        }
        .fa-trash-can {
          position: absolute;
          right: 10px;
          color: rgb(160, 9, 9);
        }
      }
    }
  }
  .addOns {
    padding: 0 3%;
    margin-bottom: 12px;
    @media (min-width: 1000px) {
      display: none;
    }
  }
}

@media (max-width: 1000px) {
  .addOnDE {
    display: none;
  }
}

@media (min-width: 1000px) {
  .addOnDE {
    background-color: #e9e9e93f;
    padding-bottom: 80px;
  }
}
</style>