<template>
  <div>
    <div v-if="!notAvail">
      <nav-header></nav-header>
      <side-menu></side-menu>

      <div class="main">
        <router-view></router-view>
      </div>
      <footer-main></footer-main>
    </div>
    <div class="message" v-if="notAvail">
      <p>Desktop Version Coming Soon, view website on yourmobile</p>
      <img src="@/assets/logo.jpeg" alt />
    </div>
  </div>
</template>

<script>
import NavHeader from "./components/NavHeader";
import FooterMain from "./components/FooterMain";
import SideMenu from "./components/SideMenu";
export default {
  name: "App",
  components: {
    NavHeader,
    FooterMain,
    SideMenu
  },
  data() {
    return {
      notAvail: false
    };
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("resize", this.resize);
    this.resize();

    this.$store.dispatch("shop/getItems");

    // console.log(window.screenX);
  },
  computed: {},
  methods: {
    resize() {
      const size = window.innerWidth;
      if (size >= 600) {
        // this.notAvail = true;
      }
    },
    onScroll() {
      let num = window.scrollY;
      // console.log(num);

      if (num > 50) {
        this.$store.dispatch("shop/toggleSideNav", false);
      }
    }
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500&display=swap");

// p,
// h1,
// h2,
// h3,
// h4,
// h5,
// li,
// a {
//   font-family: "Roboto", sans-serif;
// }

h4 {
  color: rgb(6, 46, 6);
}

.message {
  font-size: 50px;
  padding: 10%;
  height: 100vh;
  box-sizing: border-box;

  img {
    width: 300px;
    height: 300px;
  }
}

$green: rgb(0, 211, 70);

@media (min-width: 1024px) {
  .main {
    // overflow: none;
    box-sizing: border-box;
  }
}
</style>
