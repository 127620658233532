import { createStore } from "vuex";
import ShopStateModule from "./Modules/ShopState/ShopStateModule";

const store = createStore({
  modules: {
    shop: ShopStateModule
  },
  state() {
    return {};
  },
  actions: {},
  mutations: {},
  getters: {}
});

export default store;
