export default {
  getItems(state) {
    // console.log(state.shopItems[0]);
    // console.log(state);
    return state.shopItems;
  },

  getCurrentMenuState(state) {
    return state.sideMenu;
  },
  getPreviewItem(state) {
    // console.log(state.previewItem[0]);

    return state.previewItem[0];
  },
  getCartItem(state) {
    return state.cartItems;
  },

  getAccessToken(state) {
    return state.payToken;
  }
};
