<template>
  <div class="contactUsContainer">
    <h1>Contact us</h1>
    <form v-on:submit.prevent="sendInfo">
      <input class="formControl" type="text" placeholder="name" v-model="name" />
      <input class="formControl" type="text" placeholder="email" v-model="email" />

      <textarea
        class="formControl"
        name
        id
        cols="30"
        rows="10"
        placeholder="message"
        v-model="message"
      ></textarea>
      <button v-if="!messageSent" type="submit">send</button>
      <p v-if="messageSent">Thank you! your message has been sent</p>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      email: "",
      message: "",
      messageSent: false
    };
  },
  methods: {
    sendInfo() {
      const data = {
        name: this.name,
        email: this.email,
        message: this.message
      };

      fetch("https://alimuonline22.herokuapp.com/my-server/send-inquiry", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
        .then(x => {
          console.log(x);
          this.name = "";
          this.email = "";
          this.message = "";
          this.messageSent = true;

          setTimeout(() => {
            this.$router.push("/");
          }, 4000);
        })
        .catch(err => {
          console.log(err);
          this.messageSent = false;
        });
    }
  }
};
</script>


<style lang="scss" scoped>
.contactUsContainer {
  min-height: 100vh;
  padding: 10px 10%;

  .formControl {
    width: 100%;
    padding: 5px 10px;
    box-sizing: border-box;
    margin: 5px 0;
  }
  button {
    width: 100px;
    height: 30px;
    background-color: rgb(19, 59, 28);
    color: white;
  }
}
</style>