import { createRouter, createWebHistory } from "vue-router";

import HomePage from "../Pages/HomePage";
import PreviewPage from "../Pages/PreviewPage";
import ShopPage from "../Pages/ShopPage";
import CheckOut from "../Pages/CheckOut";
import ContactUs from "../Pages/ContactUs";
import AboutUs from "../Pages/AboutUs";
import LegalPage from "../Pages/LegalPage";
import ProductUpdate from "../Pages/ProductUpdate";
import AdminPage from "../Pages/AdminPage";
import PaymentCompleted from "../Pages/PaymentCompleted";

const routes = [
  { path: "/", component: HomePage },
  { path: "/store/preview/:id", component: PreviewPage },
  { path: "/store", component: ShopPage },
  { path: "/cart", component: CheckOut },
  { path: "/about", component: AboutUs },
  { path: "/help", component: ContactUs },
  { path: "/legal", component: LegalPage },
  { path: "/admin", component: AdminPage },
  { path: "/products", component: ProductUpdate },
  { path: "/thankYou!", component: PaymentCompleted }

  // { path: "/:catchAll(.*)", component: NotFound }
];

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes // short for `routes: routes`
});

export default router;
