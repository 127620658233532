<template>
  <div class="adminContainer">
    <br />
    <!-- <button @click="trigger">send</button> -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  computed: {
    token() {
      return this.$store.getters["shop/getAccessToken"];
    }
  },
  methods: {
    trigger() {
      // let payload = {
      //   // item: myItems,
      //   reciptid: "10L039192T750364C",
      //   token: this.token
      // };
      // console.log(this.token);

      localStorage.clear("myCart");

      // this.$store.dispatch("shop/getRecipt", payload);

      // return fetch(
      //   // "https://alimuonline22.herokuapp.com/my-server/capture-order/" +
      //   //   data.orderID,
      //   //
      //   "http://localhost:3000/my-server/product/7D005575MY648462R",
      //   {
      //     method: "get"
      //   }
      // )
      //   .then(response => response.json())
      //   .then(orderData => {
      //     console.log(orderData);
      //   });
    }
  }
};
</script>


<style lang="scss" scoped>
.adminContainer {
  min-height: 100vh;
  background-color: rgb(238, 238, 238);
}
</style>