<template>
  <div class="aboutUsContainer">
    <h1>about us</h1>
    <hr />
    <article>
      <h2>Alimu</h2>
      <p>
        Alimu A refugee girl whose family escaped the wars and hardship of life
        and came to a new land full of honey looking for new horizons and
        opportunities to live a better and fulfilling life. This little girl set
        and watched TV being careful to admire all the details of the different
        garments wore by her favourite artists who seemed to have had endless
        wardrobe Pieces and joyful wearing them. Every-time she saw a new music
        video or movie there was something that tied her to the art and it was
        the beauty of the garment. Fast forward few yards she found herself
        doing textile and design. Neck in Neck with music Textile and design was
        one of her best achieved subjects. She went on to study the Bachelors of
        design majoring in Fashion. After university reality kicked in. For few
        years after University her main goal remind bring the arts she used to
        admired and see the faces of the individuals happy was what kept her
        going in this direction. If you are seeing this bio then she is still
        following her dreams and she wants to thank you for being part of it.
        Dream makers are those who appreciate the art of the dreamer and share
        it with the world. Our Mission THE WORLD MEETS ALIMU
      </p>
    </article>
  </div>
</template>


<style lang="scss" scoped>
.aboutUsContainer {
  min-height: 100vh;
  padding: 10px 5%;

  p {
    text-align: justify;
    font-size: 18px;
  }
}
</style>