<template>
  <div class="productContainer">
    <button @click="addToDatabase()">add to database</button>
  </div>
</template> 

<script>
import mainData from "../State/data/data";
import { db } from "../Database/firebase";
import { collection, addDoc } from "firebase/firestore";

export default {
  data() {
    return {};
  },
  methods: {
    async addToDatabase() {
      console.log(mainData);
      try {
        const docRef = await addDoc(collection(db, "products"), {
          data: mainData
        });
        console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.productContainer {
  min-height: 100vh;
}
</style>