<template>
  <div class="slideContainer">
    <transition name="slider" mode="out-in">
      <div v-if="show" class="imageBox">
        <router-link :to="slideObject[index].buttonLink + slideObject[index].code">
          <picture>
            <source
              media="max-width:600em"
              srcset="https://firebasestorage.googleapis.com/v0/b/alimuonline.appspot.com/o/mobile%2Fspiltsec%20dress%20%20(2).jpg?alt=media&token=4a062a84-db96-4812-a74d-56db65a577e4"
            />
            <source media="max-width:768em" srcset="sd" />
            <source media="min-width:1020em" srcset="sd" />

            <img :src="slideObject[index].imgLink" />
          </picture>
          <!-- <img :src="slideObject[index].imgLink" alt /> -->
          <div :class="[slideObject[index].style]">
            <h2 v-if="slideObject[index].description">{{ slideObject[index].name }}</h2>
          </div>
        </router-link>

        <div class="icons">
          <i :class="{icon:index == 0}" @click="changeSlide(0)" class="far fa-circle"></i>
          <i :class="{icon:index == 1}" @click="changeSlide(1)" class="far fa-circle"></i>
          <i :class="{icon:index == 2}" @click="changeSlide(2)" class="far fa-circle"></i>
          <!-- <i :class="{icon:index == 3}" @click="changeSlide(3)" class="far fa-circle"></i> -->
          <!-- <i :class="{icon:index == 4}" @click="changeSlide(4)" class="far fa-circle"></i> -->
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["arrData"],
  data() {
    return {
      slideObject: [],

      index: 0,
      show: true
    };
  },
  created() {
    this.slideObject = this.arrData;
  },
  mounted() {
    // console.log("arrr datat", this.arrData);

    setInterval(() => {
      this.show = false;

      if (this.index < 2) {
        //2 represents array of slides availalbe start at 0
        this.index++;
      } else {
        this.index = 0;
      }
      setTimeout(() => {
        this.show = true;
      }, 10);
    }, 10500);
  },
  methods: {
    changeSlide(x) {
      this.index = x;
    }
  }
};
</script>

<style lang="scss" scoped>
// .slider-enter-active,
// .slider-leave-active {
//   transition: opacity 0.5s;
// }
// .slider-enter, .slider-leave-to /* .fade-leave-active below version 2.1.8 */ {
//   opacity: 0;
// }

.slider-enter-from {
  opacity: 0.7;
  // transform: ;
  // filter: blur(0px) grayscale(0);
  background: #7c7c7c;
  transform: perspective(5em);
  // transform: scale(0.7);
  // transform: translateY(100vw);
}
.slider-enter-active {
  transition: all 0.3s ease-in;
}
.slider-leave-to {
  opacity: 0.7;
  filter: blur(5px) grayscale(1);

  background-color: black;

  // transform: translateY(-100vw);
}

.slider-leave-active {
  transition: all 0.5s ease-out;
}

.icon {
  background-color: white;
  border-radius: 50%;
}

.slideContainer {
  position: relative;
  height: 100%;

  box-sizing: border-box;
  .imageBox {
    height: 100%;
    position: relative;
    @media (min-width: 769px) {
      // padding: 0 5%;
      background-color: #7c7c7c;
    }
    a {
      z-index: 2;
    }

    .icons {
      position: absolute;
      top: 86%;
      display: flex;
      justify-content: center;
      gap: 10px;
      left: 50%;
      transform: translateX(-50%);
      i {
        color: white;
        font-size: 14px;

        &:hover {
          background-color: white;
          border-radius: 50%;
        }
      }
    }
    &::before {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      // background-color: rgba(206, 6, 212, 0.233);
      background-color: rgba(102, 0, 197, 0.15);
    }
    //   width: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      @media (min-width: 769px) {
        object-fit: cover;
      }
    }

    //dynamic styles
    .left {
      display: flex;
      justify-content: center;
      // display: none;

      h2 {
        position: absolute;

        color: rgb(255, 255, 255);
        background-color: #7c7c7c34;
        margin-left: 0;
        padding: 10px 0px;
        // @media (min-width: 769px) {
        font-size: 1.5rem;
        font-weight: 100;
        text-transform: capitalize;
        // bottom: 0;
        transform: translate(-50%, -50%);
        padding: 5px 20px;
        font-family: "Roboto", sans-serif;
        font-size: 4em;
        font-weight: 600;
        width: 250px;
        letter-spacing: 5px;
        text-transform: uppercase;
        bottom: 0%;
        right: 0%;

        font-size: 3rem;

        @media (min-width: 500px) and (max-width: 768px) {
          bottom: 0%;
          right: 0%;

          transform: translate(0, 0);
        }
        @media (max-width: 499px) {
          font-size: 1rem;
          width: 50%;
          top: 90%;
          right: 0%;
          padding: 5px 0;
        }
      }
    }
  }
}
</style>