const item = [
  {
    name: "Merah pants",
    description:
      "This tie dyed flip flops are perfect for the warming weather It’s perfect for a casual outings and indoor",
    materials: ["spandex", "cotton", "Canvas", "Knitted"],
    washing: ["instruction", "Hand wash", "Do not bleach ", "Sun dried"],
    colors: ["Black"],
    pickColor: ["Black"],
    sizes: ["S", "M", "XL"],
    quantity: 1,
    price: "45",
    selectedSize: "",
    selectedColor: "",
    exchangePrice: 0,
    imgLink: "almu items pictures/alimu merah pants (1).jpg",
    altImages: [
      "almu items pictures/alimu merah pants (1).jpg",
      "almu items pictures/alimu merah pants (2).jpg",
      "almu items pictures/alimu merah pants (3).jpg",
      "almu items pictures/alimu merah pants (4).jpg"
    ],
    code: "Merahpants",
    sku: ""

    // name:'',
  },
  //
  {
    name: "ladies Merah pants",
    description: "Comfortable Stylish Nice fit ",
    materials: ["spandex", "cotton", "Canvas", "Knitted"],
    washing: ["instruction", "Hand wash", "Do not bleach ", "Sun dried"],
    colors: ["Black"],
    pickColor: ["Black"],
    sizes: ["S", "M", "XL"],
    quantity: 1,
    price: "45",
    selectedSize: "",
    selectedColor: "",
    exchangePrice: 0,
    imgLink: "almu items pictures/alimu merah pants (4).jpg",

    altImages: [
      "almu items pictures/alimu merah pants (1).jpg",
      "almu items pictures/alimu merah pants (2).jpg",
      "almu items pictures/alimu merah pants (3).jpg",
      "almu items pictures/alimu merah pants (4).jpg"
    ],
    code: "ladiesMerahpants",
    sku: ""

    // name:'',
  },

  {
    name: "Alimu Tee Men",
    description:
      "Anti-wrinkle, Anti-pilling, Breathable,Sustainable, Crew neck,Short sleeve, Men",
    materials: ["100% cotton", "cotton", "Canvas", "Knitted"],
    washing: [
      "Hand and machine washable ",
      "Opt for hand washed ",
      "Can tumble dry ",
      "Sun dried"
    ],
    colors: ["Black", "white"],
    pickColor: ["Black", "white"],
    sizes: ["S", "M", "L", "XL", "XXL", "XXXL"],
    quantity: 1,
    price: "25",
    selectedSize: "",
    selectedColor: "",
    exchangePrice: 0,
    imgLink: "almu items pictures/Alimu me Tees (3).jpg",
    altImages: [
      "almu items pictures/Alimu me Tees (1).jpg",
      "almu items pictures/Alimu me Tees (2).jpg",
      "almu items pictures/Alimu me Tees (3).jpg",
      "almu items pictures/Alimu me Tees (4).jpg"
    ],
    code: "AlimuTeeMan",
    sku: ""

    // name:'',
  },
  {
    name: "Alimu Tee Ladies",
    description:
      "Anti-wrinkle, Anti-pilling, Breathable,Sustainable, Crew neck,Short sleeve, Men",
    materials: ["100% cotton", "cotton", "Canvas", "Knitted"],
    washing: [
      "Hand and machine washable ",
      "Opt for hand washed ",
      "Can tumble dry ",
      "Sun dried"
    ],
    colors: ["Black", "white"],
    pickColor: ["Black", "white"],
    sizes: ["S", "M", "L", "XL", "XXL", "XXXL"],
    quantity: 1,
    price: "25",
    selectedSize: "",
    selectedColor: "",
    exchangePrice: 0,
    imgLink: "almu items pictures/alimu ladies teeshirts (4).jpg",
    altImages: [
      "almu items pictures/alimu ladies teeshirts (1).jpg",
      "almu items pictures/alimu ladies teeshirts (2).jpg",
      "almu items pictures/alimu ladies teeshirts (3).jpg",
      "almu items pictures/alimu ladies teeshirts (4).jpg"
    ],
    code: "AlimuTeeLadies",
    sku: ""

    // name:'',
  },

  {
    name: "Ture set",
    description:
      "Knitwear Two pieceWide leg Solid colour Shoulder padding Comfotable as it is stylish ",
    materials: ["Spandex / Cotton", "Computer knitted "],
    washing: ["Fashion washable ", "No bleaching ", "Tumble dryer  "],
    colors: ["Black", "Pink", "Green", "#AB9370", "Yellow"],
    pickColor: ["Black", "Pink", "Green", "Khaki", "Yellow"],
    sizes: ["S", "M", "L", "XL", "XXL"],
    quantity: 1,
    price: "54.45",
    selectedSize: "",
    selectedColor: "",
    exchangePrice: 0,
    imgLink: "almu items pictures/tureset black (2).jpg",
    altImages: [
      "almu items pictures/tureset black.jpg",
      "almu items pictures/tureset black (1).jpg",
      "almu items pictures/tureset black (2).jpg",
      "almu items pictures/tureset black (3).jpg"
    ],
    code: "Tureset",
    sku: ""

    // name:'',
  },

  {
    name: "split second dress",
    description: "Breathable and sustainable  ",
    materials: ["Spandex", "polyester", "", "Computer knitted "],
    washing: ["Fashion washable ", "No bleaching ", "Tumble dryer "],
    colors: ["beige", "#006666"],
    pickColor: ["beige", "deep cyan"],
    sizes: ["S", "M", "L", "XL", "XXL"],
    quantity: 1,
    price: "44.95",
    selectedSize: "",
    selectedColor: "",
    exchangePrice: 0,
    imgLink: "almu items pictures/spiltsec dress  (1).jpg",
    altImages: [
      // name:'',
      "almu items pictures/spiltsec dress  (1).jpg",
      "almu items pictures/spiltsec dress  (2).jpg",
      "almu items pictures/spiltsec dress  (3).jpg"
    ],
    code: "spiltseconddress",
    sku: ""

    // name:'',
  },
  {
    name: "Puff sleeve dress",
    description: " Modest and vintage Straight style Sustainable and washable",
    materials: ["polyester", "cotton", "poly cotton ", "Computer knitted "],
    washing: [
      "Fashion washable ",
      "No bleaching ",
      "Tumble dryer",
      "Dry cleanable"
    ],
    colors: ["Red", "Blue", "Green"],
    pickColor: ["Red", "Blue", "Green"],
    sizes: ["S", "M", "L", "XL", "XXL"],
    quantity: 1,
    price: "60",
    selectedSize: "",
    selectedColor: "",
    exchangePrice: 0,
    imgLink: "almu items pictures/puff sleeve dress (1).jpg",
    altImages: [
      "almu items pictures/puff sleeve dress (2).jpg",
      "almu items pictures/puff sleeve dress (3).jpg",
      "almu items pictures/puff sleeve dress (4).jpg",
      "almu items pictures/puff sleeve dress (5).jpg",
      "almu items pictures/puff sleeve dress (6).jpg",
      "almu items pictures/puff sleeve dress (7).jpg"
    ],
    code: "Puffsleevedress",
    sku: ""

    // name:'',
  },
  {
    name: "Pearl ripped flared jeans",
    description: "Comfortable fabric Ripped knee jeans",
    materials: ["Material 100% cotton"],
    washing: ["Fashion washable ", "No bleaching ", "Tumble dryer  "],
    colors: ["#99CCFF", "#0D47A1", "#17202A"],
    pickColor: ["light blue", "Dark blue", "black"],
    sizes: ["XS", "S", "M", "L", "XL", "XXL"],
    quantity: 1,
    price: "54",
    selectedSize: "",
    selectedColor: "",
    exchangePrice: 0,
    imgLink: "almu items pictures/pearl ripped jeans (1).jpg",
    altImages: ["almu items pictures/pearl ripped jeans (2).jpg"],
    code: "Pearlrippedflaredjeans",
    sku: ""

    // name:'',
  },
  {
    name: "Pic see dress set",
    description: " See through dress O neck Breathable Washable",
    materials: ["Spandex / polyester", "knitted set "],
    washing: ["Fashion washable ", "No bleaching ", "Tumble dryer  "],
    colors: ["Black", "orange", "Turquoise", "#AB9370", "Beige"],
    pickColor: ["Black", "orange", "Turquoise", "Khaki", "Beige"],
    sizes: ["XS", "S", "M", "L", "XL", "XXL"],
    quantity: 1,
    price: "39.95",
    selectedSize: "",
    selectedColor: "",
    exchangePrice: 0,
    imgLink: "almu items pictures/pic see dress (1).jpg",
    altImages: [
      "almu items pictures/pic see dress (1).jpg",
      "almu items pictures/pic see dress (2).jpg",
      "almu items pictures/pic see dress (3).jpg",
      "almu items pictures/pic see dress (4).jpg",
      "almu items pictures/pic see dress (5).jpg"
    ],
    code: "Picseedressset",
    sku: ""

    // name:'',
  },
  {
    name: "Puffer Jacket",
    description: " 51% white duck down",
    materials: ["Spandex / Cotton", "Computer knitted "],
    washing: [
      "Fashion washable ",
      "No bleaching ",
      "Hang dry",
      "Hand wash cold Machine washable"
    ],
    colors: ["Black", "white", "#AB9370", "#9E9D24"],
    pickColor: ["Black", "white", "Khaki", "dark green"],
    sizes: ["S", "M", "L"],
    quantity: 1,
    price: "34.95",
    selectedSize: "",
    selectedColor: "",
    exchangePrice: 0,
    imgLink: "almu items pictures/puffer jacket (1).jpg",
    altImages: [
      "almu items pictures/puffer jacket (1).jpg",
      "almu items pictures/puffer jacket (2).jpg",
      "almu items pictures/puffer jacket (3).jpg",
      "almu items pictures/puffer jacket (4).jpg"
    ],
    code: "PufferJacket",
    sku: ""

    // name:'',
  },
  {
    name: "Asymmetrical one sleeve dress",
    description: " knitted One shoulder sleeve Breathable Sustainable ",
    materials: ["Spandex / polyester "],
    washing: ["Dry cleaner"],
    colors: ["Black", "Orange", "Red"],
    pickColor: ["Black", "Orange", "Red"],
    sizes: ["S", "M", "L", "XL", "XXL", "3XL"],
    quantity: 1,
    price: "41.95",
    selectedSize: "",
    selectedColor: "",
    exchangePrice: 0,
    imgLink: "almu items pictures/assymetricle one sleeve dressred (1).jpg",
    altImages: [
      "almu items pictures/assymetricle one sleeve dressred (1).jpg",
      "almu items pictures/assymetricle one sleeve dressred (2).jpg",
      "almu items pictures/assymetricle one sleeve dressred.jpg"
    ],
    code: "Asymmetricalonesleevedress",
    sku: ""

    // name:'',
  },
  {
    name: "Candy Nude clear Pump",
    description: "PVC clear Stiletto Pump Midsole rubber, Sole: Gold",
    materials: ["rubber"],
    washing: [],
    colors: ["#EBC8B2"],
    pickColor: ["nude"],
    sizes: ["S", "M", "XL"],
    quantity: 1,
    price: "79.95",
    selectedSize: "",
    selectedColor: "",
    exchangePrice: 0,
    imgLink: "almu items pictures/assymetricle one sleeve dressred (1).jpg",
    altImages: ["almu items pictures/assymetricle one sleeve dressred (1).jpg"],
    code: "clearPump",
    sku: ""

    // name:'',
  },
  {
    name: "Alimu Sock ",
    description: "Antibacterial Breathable Moister Absorption Perspiration",
    materials: ["80% cotton", "15% Spandex", "5% Polyester"],
    washing: [],
    colors: ["Black", "white"],
    pickColor: ["Black", "white"],

    sizes: ["5-7 S", "7-9 M", "9-11 L", "11-13XL"],
    quantity: 1,
    price: "19.95",
    selectedSize: "",
    selectedColor: "",
    exchangePrice: 0,
    imgLink: "almu items pictures/alimu shocks (1).jpeg",
    altImages: [
      "almu items pictures/alimu shocks (1).jpeg",
      "almu items pictures/alimu shocks (2).jpeg"
    ],
    code: "AlimuSock",
    sku: ""

    // name:'',
  },
  {
    name: "All love Sneakers",
    description: " Main Product Casual shoes, canvas Shoes, Sneakers",
    materials: ["Spandex / Cotton", "Computer knitted "],
    washing: ["Fashion washable ", "No bleaching ", "Tumble dryer  "],
    colors: ["Black", "Orange", "#9E9D24", "white"],
    pickColor: ["Black", "Orange", "dark green", "white"],
    sizes: ["7", "8", "9", "10", "11", "12", "13"],
    quantity: 1,
    price: "120",
    selectedSize: "",
    selectedColor: "",
    exchangePrice: 0,
    imgLink: "almu items pictures/Alimu shoes (6).jpeg",
    altImages: [
      "almu items pictures/Alimu shoes (1).jpeg",
      "almu items pictures/Alimu shoes (2).jpeg",
      "almu items pictures/Alimu shoes (3).jpeg",
      "almu items pictures/Alimu shoes (4).jpeg",
      "almu items pictures/Alimu shoes (5).jpeg",
      "almu items pictures/Alimu shoes (6).jpeg",
      "almu items pictures/Alimu shoes (7).jpeg"
    ],
    code: "AllloveSneakers",
    sku: ""

    // name:'',
  }

  // testing
  // {
  //   name: "test product too",
  //   description: " Main Product Casual shoes, canvas Shoes, Sneakers",
  //   materials: ["Spandex / Cotton", "Computer knitted "],
  //   washing: ["Fashion washable ", "No bleaching ", "Tumble dryer  "],
  //   colors: ["Black", "Orange", "#9E9D24", "white"],
  //   sizes: ["7", "8", "9", "10", "11", "12", "13"],
  //   quantity: 1,
  //   price: "0.03",
  //   selectedSize: "",
  //   selectedColor: "",
  //   exchangePrice: 0,
  //   imgLink:
  //     "https://firebasestorage.googleapis.com/v0/b/alimuonline.appspot.com/o/alimusocksunisexwinter%2Fshoes%2F04.jpeg?alt=media&token=3f90d679-a5ed-4e2e-911f-e5131cbc75c0",
  //   altImages: [
  //     "https://firebasestorage.googleapis.com/v0/b/alimuonline.appspot.com/o/alimusocksunisexwinter%2Fshoes%2F01.jpeg?alt=media&token=5bc21ec8-3680-4d93-8d8f-2d6155ff8e45",
  //     "https://firebasestorage.googleapis.com/v0/b/alimuonline.appspot.com/o/alimusocksunisexwinter%2Fshoes%2F06.jpeg?alt=media&token=18a98c11-a6f9-434d-8080-bea341d4b189",
  //     "https://firebasestorage.googleapis.com/v0/b/alimuonline.appspot.com/o/alimusocksunisexwinter%2Fshoes%2F07%20(1).jpeg?alt=media&token=74496b9f-774b-45d9-a244-e2188a61180c",
  //     "https://firebasestorage.googleapis.com/v0/b/alimuonline.appspot.com/o/alimusocksunisexwinter%2Fshoes%2F05.jpeg?alt=media&token=105471ab-c9eb-4d0e-987f-eef23e0947ac",
  //     "https://firebasestorage.googleapis.com/v0/b/alimuonline.appspot.com/o/alimusocksunisexwinter%2Fshoes%2F02.jpeg?alt=media&token=b824c83d-514e-4c00-8bc6-c23b9dc47ddb"
  //   ],
  //   code: "test2",
  //   sku: ""

  //   // name:'',
  // },
  // {
  //   name: "test produc 1",
  //   description: " Main Product Casual shoes, canvas Shoes, Sneakers",
  //   materials: ["Spandex / Cotton", "Computer knitted "],
  //   washing: ["Fashion washable ", "No bleaching ", "Tumble dryer  "],
  //   colors: ["Black", "Orange", "#9E9D24", "white"],
  //   sizes: ["7", "8", "9", "10", "11", "12", "13"],
  //   quantity: 1,
  //   price: "0.05",
  //   selectedSize: "",
  //   selectedColor: "",
  //   exchangePrice: 0,
  //   imgLink:
  //     "https://firebasestorage.googleapis.com/v0/b/alimuonline.appspot.com/o/alimusocksunisexwinter%2Fshoes%2F04.jpeg?alt=media&token=3f90d679-a5ed-4e2e-911f-e5131cbc75c0",
  //   altImages: [
  //     "https://firebasestorage.googleapis.com/v0/b/alimuonline.appspot.com/o/alimusocksunisexwinter%2Fshoes%2F01.jpeg?alt=media&token=5bc21ec8-3680-4d93-8d8f-2d6155ff8e45",
  //     "https://firebasestorage.googleapis.com/v0/b/alimuonline.appspot.com/o/alimusocksunisexwinter%2Fshoes%2F06.jpeg?alt=media&token=18a98c11-a6f9-434d-8080-bea341d4b189",
  //     "https://firebasestorage.googleapis.com/v0/b/alimuonline.appspot.com/o/alimusocksunisexwinter%2Fshoes%2F07%20(1).jpeg?alt=media&token=74496b9f-774b-45d9-a244-e2188a61180c",
  //     "https://firebasestorage.googleapis.com/v0/b/alimuonline.appspot.com/o/alimusocksunisexwinter%2Fshoes%2F05.jpeg?alt=media&token=105471ab-c9eb-4d0e-987f-eef23e0947ac",
  //     "https://firebasestorage.googleapis.com/v0/b/alimuonline.appspot.com/o/alimusocksunisexwinter%2Fshoes%2F02.jpeg?alt=media&token=b824c83d-514e-4c00-8bc6-c23b9dc47ddb"
  //   ],
  //   code: "test1",
  //   sku: ""

  //   // name:'',
  // },

  // {
  //   name: "test product three",
  //   description: " Main Product Casual shoes, canvas Shoes, Sneakers",
  //   materials: ["Spandex / Cotton", "Computer knitted "],
  //   washing: ["Fashion washable ", "No bleaching ", "Tumble dryer  "],
  //   colors: ["Black", "Orange", "#9E9D24", "white"],
  //   sizes: ["7", "8", "9", "10", "11", "12", "13"],
  //   quantity: 1,
  //   price: "0.01",
  //   selectedSize: "",
  //   selectedColor: "",
  //   exchangePrice: 0,
  //   imgLink:
  //     "https://firebasestorage.googleapis.com/v0/b/alimuonline.appspot.com/o/alimusocksunisexwinter%2Fshoes%2F04.jpeg?alt=media&token=3f90d679-a5ed-4e2e-911f-e5131cbc75c0",
  //   altImages: [
  //     "https://firebasestorage.googleapis.com/v0/b/alimuonline.appspot.com/o/alimusocksunisexwinter%2Fshoes%2F01.jpeg?alt=media&token=5bc21ec8-3680-4d93-8d8f-2d6155ff8e45",
  //     "https://firebasestorage.googleapis.com/v0/b/alimuonline.appspot.com/o/alimusocksunisexwinter%2Fshoes%2F06.jpeg?alt=media&token=18a98c11-a6f9-434d-8080-bea341d4b189",
  //     "https://firebasestorage.googleapis.com/v0/b/alimuonline.appspot.com/o/alimusocksunisexwinter%2Fshoes%2F07%20(1).jpeg?alt=media&token=74496b9f-774b-45d9-a244-e2188a61180c",
  //     "https://firebasestorage.googleapis.com/v0/b/alimuonline.appspot.com/o/alimusocksunisexwinter%2Fshoes%2F05.jpeg?alt=media&token=105471ab-c9eb-4d0e-987f-eef23e0947ac",
  //     "https://firebasestorage.googleapis.com/v0/b/alimuonline.appspot.com/o/alimusocksunisexwinter%2Fshoes%2F02.jpeg?alt=media&token=b824c83d-514e-4c00-8bc6-c23b9dc47ddb"
  //   ],
  //   code: "test3",
  //   sku: ""

  //   // name:'',
  // }

  //
];
export default item;
