import { createApp } from "vue";
import App from "./App.vue";
import router from "./Route/AppRoutes";
import store from "./State/store";

const app = createApp(App);

app.use(router); // use the router
app.use(store); // user the store

app.mount("#app"); // mount the app to index.js
