<template>
  <div>
    <nav>
      <ul class="mainNav">
        <li class="menu">
          <i v-if="!getMenuState" @click="toggleMenu" class="fa-solid fa-bars"></i>
          <i v-if="getMenuState" @click="toggleMenu" class="fa-solid fa-rectangle-xmark"></i>
          <!-- <i class="fa-solid fa-circle-xmark"></i> -->
        </li>
        <li class="logo">
          <router-link to="/">
            <b>ALIMU</b>
          </router-link>
        </li>

        <li class="cart">
          <!--  -->
          <ul class="desktopNav">
            <li>
              <router-link to="/">Home</router-link>
            </li>
            <li class="store">
              <router-link to="/store">Collection</router-link>
            </li>
            <li class="store" disabled>
              <router-link to="/store">Latest</router-link>
            </li>
            <!-- <li>
              <router-link to="/about">about</router-link>
            </li>-->
            <!-- <li>
              <router-link to="/help">contact us</router-link>
            </li>-->
            <!-- <li>
              <router-link to="/legal">legal</router-link>
            </li>-->
            <!-- <li>
              <a>support</a>
            </li>-->
          </ul>

          <!--  -->
          <!--  -->
          <router-link to="/cart" class="cartIcon">
            <i class="fa-solid fa-cart-shopping">
              <span class="cart__count">
                <span v-if="getCartItems">{{getCartItems.length}}</span>
                <span v-if="!getCartItems">0</span>
              </span>
            </i>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>


<script>
export default {
  data() {
    return {};
  },
  computed: {
    getMenuState() {
      return this.$store.getters["shop/getCurrentMenuState"];
    },
    getCartItems() {
      return this.$store.getters["shop/getCartItem"];
    }
  },
  methods: {
    toggleMenu() {
      let currentState = this.getMenuState;
      currentState == true ? (currentState = false) : (currentState = true);
      this.$store.dispatch("shop/toggleSideNav", currentState);
    }
  }
};
</script>



<style lang="scss" scoped>
nav {
  // background-color: #202b24;
  background-color: white;
  height: 50px;
  // border-top: solid 2px #aca107;
  border-bottom: solid 2px #7c7c7c50;

  ul {
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0;
    padding: 0 5%;
    list-style-type: none;
    box-sizing: border-box;

    justify-content: space-between;
    align-items: center;
    border: none;

    .logo {
      border: solid 2px #e6e6e644;
      padding: 1px 10px;
      // background-color: rgba(255, 255, 255, 0.2);
      a {
        // text-shadow: 5px 5px 20px #aca107;
      }
    }

    .cart {
      &__count {
        font-size: 12px;
        padding: 0 5px;
      }
      @media (max-width: 800px) {
        .desktopNav {
          display: none;
        }
      }
    }

    li {
      margin: 0;
      padding: 0;
      font-size: 20px;
      a {
        text-decoration: none;
        color: rgb(0, 0, 0);
      }
    }

    i {
      margin: 0;
      padding: 0;
      color: rgb(0, 0, 0);
    }
  }

  @media (min-width: 1024px) and(max-width: 1080px) {
    .mainNav {
      padding: 0 5%;
      font-size: 2rem;
      display: grid;
      grid-template-columns: auto auto;
      .menu {
        display: none;
      }
      box-sizing: border-box;
      width: 100%;
      .logo {
        font-size: 2rem;
      }

      .cart {
        display: flex;
        // justify-content: space-between;
        gap: 20px;
        margin: 0;
        // width: 50%;
        font-size: 1.5rem;
        flex: 0 0 50%;
        .desktopNav {
          flex: 0 1 60%;
          padding: 0;
          margin: 0;

          li {
            padding: 0px 30px;
            margin: 0 2px;
            border: solid 2px rgba(230, 230, 230, 0.068);
            &:hover {
              background-color: #aaaaaa23;
            }
          }
        }
        .cartIcon {
          flex: 0 1 30%;
          font-size: 1.8rem;
        }
      }
    }
  }

  @media (min-width: 1081px) {
    .mainNav {
      padding: 0 10%;
      font-size: 2rem;
      display: grid;
      grid-template-columns: auto auto;
      .menu {
        display: none;
      }
      box-sizing: border-box;
      width: 100%;
      .logo {
        font-size: 2rem;
      }

      .cart {
        display: flex;
        // justify-content: space-between;
        gap: 20px;
        margin: 0;
        // width: 50%;
        font-size: 1.5rem;
        flex: 0 0 50%;
        .desktopNav {
          flex: 0 1 60%;
          padding: 0;
          margin: 0;

          li {
            padding: 0px 30px;
            margin: 0 2px;
            border: solid 2px rgba(230, 230, 230, 0.068);
            &:hover {
              background-color: #aaaaaa23;
            }
          }
        }
        .cartIcon {
          flex: 0 1 30%;
          font-size: 1.8rem;
        }
      }
    }
  }
}
</style> 