<template>
  <section class="trendingContainer">
    <h2>{{heading}}</h2>
    <div class="trendingContainer__itemBox">
      <div v-for=" item in arrdata" :key="item.code">
        <p>{{item.name}}</p>
        <img @click="view(item.code)" :src="desktop+item.imgLink" :alt="item.name" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["heading", "arrdata"],
  data() {
    return {
      desktop: "https://img.imageboss.me/alimupic/cover/500x800/"
    };
  },
  mounted() {
    // console.log(this.arrdata);
  },
  methods: {
    view(x) {
      // :to="'/store/preview/' + item.code"
      // console.log(this.$route.matched[0].path);
      const p = this.$route.matched[0].path;

      if (p == "/store/preview/:id") {
        // console.log(this.$route.path);
        // console.log("here");
        setTimeout(() => {
          location.reload();
          scroll(0, 0);
        }, 1000);
      }

      this.$router.replace("/store/preview/" + x);
    }
  }
};
</script>


<style lang="scss" scoped>
.trendingContainer {
  padding: 0 5px;
  font-family: "Roboto", sans-serif;

  @media (min-width: 769px) {
    width: 100%;
    padding: 10px 10%;
    box-sizing: border-box;
    // display: none;
  }
  @media (min-width: 1024px) and(max-width: 1080px) {
    padding: 0 5%;
    margin: 50px 0;
  }
  h2 {
    border-bottom: solid 2px #000000;
    // margin: 20px 10px 0 12px;
    padding: 2px 0px;
    display: inline-block;
    font-weight: 400;
    // color: #021605;
    color: #000000;
    margin: 20px 0 0 0;
    @media (min-width: 769px) {
      margin: 20px 0 10px 0;
    }
  }

  &__itemBox {
    padding: 5px 0px;
    display: grid;
    @media (min-width: 769px) {
      width: 100%;
      // display: none;
      gap: 20px;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    }
    @media (max-width: 375px) {
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    }
    @media (min-width: 375px) and (max-width: 768px) {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
    // grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 5px;
    div {
      flex: 0 0 30%;
      // background: black;
      height: 200px;
      width: 100%;
      position: relative;
      @media (min-width: 769px) {
        height: 350px;
        background-color: rgb(223, 221, 221);
      }
      p {
        position: absolute;
        background-color: #000000ab;

        color: white;
        bottom: 0;
        margin: 0;
        padding: 2px 10px;
        right: 0px;
        font-style: italic;
        font-weight: 200;
        letter-spacing: 2px;
      }

      &:nth-child(1) {
      }
      &:nth-child(2) {
      }
      &:nth-child(3) {
      }
      &:nth-child(4) {
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media (min-width: 769px) {
          object-fit: cover;
        }
      }
    }
  }
}
</style>