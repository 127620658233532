const item = [
  //
  {
    name: "split second dress",
    description: "Breathable and sustainable  ",
    materials: ["Spandex", "polyester", "", "Computer knitted "],
    washing: ["Fashion washable ", "No bleaching ", "Tumble dryer "],
    colors: ["beige", "#006666"],
    sizes: ["S", "M", "L", "XL", "XXL"],
    quantity: 1,
    price: "44.95",
    selectedSize: "",
    selectedColor: "",
    exchangePrice: 0,
    imgLink:
      "https://img.imageboss.me/alimupic/cover/1080x500/almu items pictures/spiltsec dress  (1).jpg",
    altImages: [],
    code: "spiltseconddress",
    style: "left",
    buttonLink: "/store/preview/",

    sku: ""

    // name:'',
  },
  //
  // {
  //   name: "Alimu Tee Men",
  //   description:
  //     "Anti-wrinkle, Anti-pilling, Breathable,Sustainable, Crew neck,Short sleeve, Men",
  //   materials: ["100% cotton", "cotton", "Canvas", "Knitted"],
  //   washing: [
  //     "Hand and machine washable ",
  //     "Opt for hand washed ",
  //     "Can tumble dry ",
  //     "Sun dried"
  //   ],
  //   colors: ["Black", "white"],
  //   sizes: ["S", "M", "L", "XL", "XXL", "XXXL"],
  //   quantity: 1,
  //   price: "25",
  //   selectedSize: "",
  //   selectedColor: "",
  //   exchangePrice: 0,
  //   imgLink: "",
  //   altImages: [],
  //   code: "AlimuTeeMan",
  //   style: "left",
  //   buttonLink: "/store/preview/",

  //   sku: ""

  //   // name:'',
  // },

  {
    name: "Ture set",
    description:
      "Knitwear Two pieceWide leg Solid colour Shoulder padding Comfotable as it is stylish ",
    materials: ["Spandex / Cotton", "Computer knitted "],
    washing: ["Fashion washable ", "No bleaching ", "Tumble dryer  "],
    colors: ["Black", "Pink", "Green", "#AB9370", "Yellow"],
    sizes: ["S", "M", "L", "XL", "XXL"],
    quantity: 1,
    price: "54.45",
    selectedSize: "",
    selectedColor: "",
    exchangePrice: 0,
    imgLink:
      "https://img.imageboss.me/alimupic/cover/2000x2500/almu items pictures/tureset black (1).jpg",

    altImages: [],
    code: "Tureset",
    style: "left",
    buttonLink: "/store/preview/",
    sku: ""

    // name:'',
  },

  {
    name: "Puffer Jacket",
    description: " 51% white duck down",
    materials: ["Spandex / Cotton", "Computer knitted "],
    washing: [
      "Fashion washable ",
      "No bleaching ",
      "Hang dry",
      "Hand wash cold Machine washable"
    ],
    colors: ["Black", "white", "#AB9370", "#9E9D24"],
    sizes: ["S", "M", "L"],
    quantity: 1,
    price: "34.95",
    selectedSize: "",
    selectedColor: "",
    exchangePrice: 0,
    imgLink:
      "https://img.imageboss.me/alimupic/cover/2080x1000/almu items pictures/puffer jacket (1).jpg",

    code: "PufferJacket",
    style: "left",
    buttonLink: "/store/preview/",

    sku: ""

    // name:'',
  }

  // {
  //   name: "Pearl ripped flared jeans",
  //   description: "Comfortable fabric Ripped knee jeans",
  //   materials: ["Material 100% cotton"],
  //   washing: ["Fashion washable ", "No bleaching ", "Tumble dryer  "],
  //   colors: ["#99CCFF", "#0D47A1", "#17202A"],
  //   sizes: ["XS", "S", "M", "L", "XL", "XXL"],
  //   quantity: 1,
  //   price: "54",
  //   selectedSize: "",
  //   selectedColor: "",
  //   exchangePrice: 0,
  //   imgLink:
  //     "https://firebasestorage.googleapis.com/v0/b/alimuonline.appspot.com/o/pearlrippedjeans%2F096A4317db.jpg?alt=media&token=dd820ef9-8ca0-46c3-9baf-3e95f787b9c3",
  //   altImages: [
  //     "https://firebasestorage.googleapis.com/v0/b/alimuonline.appspot.com/o/pearlrippedjeans%2F096A4296black.jpg?alt=media&token=df5a2f29-3775-4a9f-b90d-f9bbcfa162e7",
  //     "https://firebasestorage.googleapis.com/v0/b/alimuonline.appspot.com/o/pearlrippedjeans%2F096A4317db.jpg?alt=media&token=dd820ef9-8ca0-46c3-9baf-3e95f787b9c3"
  //   ],
  //   code: "Pearlrippedflaredjeans",
  //   style: "left",
  //   buttonLink: "/store/preview/",

  //   sku: ""
  // }

  //
];
export default item;
