<template>
  <div class="completedContainer">
    <div v-if="loading" class="lds-ripple">
      <div></div>
      <div></div>
    </div>
    <div v-if="!loading">
      <div class="message">
        <h4>Payment Completed!</h4>
        <p>thank you for your purchase</p>
      </div>
      <div class="button">
        <button>
          <router-link to="/store">continue shopping</router-link>
        </button>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      loading: true
    };
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 3000);
  }
};
</script>

<style lang="scss" scoped>
.completedContainer {
  min-height: 100vh;

  .message {
    text-align: center;
    height: 50%;
    h4 {
      text-align: center;
      margin: 0;
      padding: 90px 20px;
      border-radius: 50%;
      background-color: rgb(209, 209, 209);
      //   height: 200px;
      //   width: 200px;
      font-size: 40px;
    }
    p {
      font-size: 18px;
    }
  }
  .button {
    // position: relative;
    display: flex;
    justify-content: center;
    button {
      position: absolute;
      padding: 10px;
      color: green;
      a {
        text-decoration: none;
      }
    }
  }

  //loading
  .lds-ripple {
    display: inline-block;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    // background-color: black;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid rgb(43, 7, 7);
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }

  //loading
}
</style>