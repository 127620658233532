export default {
  OpenCloseSideNav(state, booleanVal) {
    // console.log(state.selectedItem);
    // state.selectedItem[0].exchangePrice * state.currency.value;
    return (state.sideMenu = booleanVal);
  },
  getPreviewItem(state, payload) {
    // console.log(payload, state.shopItems);

    state.previewItem = state.shopItems.filter(x => x.code == payload);
  },

  addToCart(state, payload) {
    // console.log(state, payload);
    // console.log(state);

    let cartItem = [];
    const x = localStorage.getItem("myCart");
    const storageData = JSON.parse(x);

    cartItem = storageData;

    // console.log(cartItem);
    if (!cartItem) {
      cartItem = [payload];
    } else {
      cartItem.unshift(payload);
    }
    // console.log(payload);

    const data = JSON.stringify(cartItem);
    localStorage.setItem("myCart", data);

    state.cartItems = cartItem;
  },
  getItems(state) {
    const x = localStorage.getItem("myCart");
    const storageData = JSON.parse(x);

    state.cartItems = storageData;
  },
  removeItem(state, sku) {
    const datax = state.cartItems.filter(x => x.sku !== sku);
    const x = JSON.stringify(datax);
    localStorage.setItem("myCart", x);

    state.cartItems = datax;
  },
  updatePayAccessToken(state) {
    // console.log("updating");

    return fetch("https://alimuonline22.herokuapp.com/my-server/token", {
      method: "POST"
    })
      .then(function(res) {
        if (!res.ok) {
          alert("Something went wrong");
        }

        return res.json();
      })
      .then(data => {
        // console.log("token", data);

        // setting cookie
        let name = "nekot"; // name of cookie
        const value = JSON.stringify(data.tokenBody.access_token);
        var date = new Date();
        date.setTime(date.getTime() + data.tokenBody.expires_in * 1000);
        var expires = "; expires=" + date.toGMTString();
        document.cookie = name + "=" + value + expires + "; path=/";
        //
        //
        state.payToken = JSON.stringify(data.tokenBody.access_token);
      });
  },
  getSavedToken(state) {
    const cname = "nekot";
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        state.payToken = c.substring(name.length, c.length);
        return c.substring(name.length, c.length);
      }
    }
    state.payToken = null;
    return "";
    //
  },
  getRecipt(state, payload) {
    // get recipt
    // // console.log(state);
    // console.log(payload);

    // console.log("mutation reached");

    fetch(
      // "https://alimuonline22.herokuapp.com/my-server/capture-order/" +
      //   data.orderID,
      //
      "https://alimuonline22.herokuapp.com/my-server/product/" +
        payload.reciptid +
        "/" +
        payload.token,
      {
        method: "Get"
      }
    )
      .then(response => response.json())
      .then(orderData => {
        // console.log("mustation recipt data", orderData);
        // combine recipt data with purchased items from cart data, add size,color and other features to recipt
        const reciptBody = orderData.body;
        const reciptItems = reciptBody.purchase_units[0].items;
        const myItems = payload.item.items;

        // console.log("myItems", myItems);
        // console.log("recipt items", reciptItems);

        for (let i = 0; i < reciptItems.length; i++) {
          if (reciptItems[i].sku == myItems[i].sku) {
            // console.log(reciptItems[i].sku);
            reciptItems[i].size = myItems[i].sizes;
            reciptItems[i].color = myItems[i].colors;
          }
        }

        let recipt = {
          createtime: reciptBody.create_time,
          id: reciptBody.id,
          buyerAddress: reciptBody.purchase_units[0].shipping.address,
          buyerFullName: reciptBody.purchase_units[0].shipping.name.full_name,
          buyer: reciptBody.payer,
          payee: reciptBody.purchase_units[0].payee,
          items: reciptItems,
          itemTotal:
            reciptBody.purchase_units[0].amount.breakdown.item_total.value,
          currency:
            reciptBody.purchase_units[0].amount.breakdown.item_total
              .currency_code,
          sellerInfo: reciptBody.purchase_units[0].payments.captures[0]
        };

        // console.log("final object", recipt);

        //send email to both buyer and seller

        fetch("https://alimuonline22.herokuapp.com/my-server/send-recipt", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(recipt)
        })
          .then(x => console.log(x))
          .catch(err => console.log(err));
        //

        //
      });

    //
  },
  clearMyCart(state) {
    localStorage.removeItem("myCart");
    state.cartItems = [];
  }
};
