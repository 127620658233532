<template>
  <div class="legalContainer">
    <div class="nav">
      <!-- <ul>
        <li>
          <a>TERMS OF SERVICE</a>
        </li>
        <li>
          <a>User Obligations</a>
        </li>
        <li>
          <a>returns</a>
        </li>
      </ul>-->
      <img width="100%" src="../assets/logo.jpeg" alt />
    </div>
    <div class="contents">
      <div id="contents__terms">
        <h3>TERMS OF SERVICE</h3>
        <p>
          EFFECTIVE DATE: April 04 2022
          <br />
          <br />Alimu Fashion, (“We” or “Alimu”) is the operator of the website located at www.Alimu.com (the “Website”). This Agreement governs your use of the Website and your purchase of any products from Alimu (the “Products”).
          The Website and Products are offered subject to your acceptance of these Terms and Conditions (“Terms” or “Agreement”). These Terms constitute a legally binding agreement between you and Alimu, and you should read them carefully. By agreeing to these Terms, you also agree to the Alimu Privacy Policy, Online Return Policy, Store Returns Policy and Shipping Policy, each of which is expressly incorporated by reference herein.
          <br />THIS AGREEMENT CONTAINS AN ARBITRATION AGREEMENT AND CLASS ACTION WAIVER THAT WAIVE YOUR RIGHT TO A COURT HEARING OR JURY TRIAL OR TO PARTICIPATE IN A CLASS ACTION. ARBITRATION IS MANDATORY AND THE EXCLUSIVE REMEDY FOR ANY AND ALL DISPUTES UNLESS SPECIFIED BELOW OR IF YOU OPT-OUT. YOU MUST REVIEW THIS DOCUMENT IN ITS ENTIRETY BEFORE ACCESSING, USING, OR BUYING ANY PRODUCT THROUGH THE WEBSITE.
        </p>
      </div>
      <!--  -->
      <div id="contents__UserObligations">
        <h3>User Obligations</h3>
        <p>
          By downloading, accessing or using the Website, you represent that you are at least eighteen (18) years old or the legal age of majority, whichever is greater, and you are agreeing to these Terms. You also agree to abide by all applicable local, state, and national laws and regulations with respect to your use of the Website. You further represent you shall at all times provide true, accurate, current, and complete information (and updates thereto) when submitting information to Alimu through the Website. You shall only use the Website as permitted by this Agreement, and you shall not use the Website or the Content for any commercial, political, obscene, illegal, or inappropriate purpose. Alimu reserves the right, in its sole discretion, to terminate your access to the Website for any or no reason. If you register for an account on the Website, you may be required to designate an email address and password, and there may be additional requirements as designated by Alimu from time to time. You agree to assume all responsibility concerning your use of the Website, including all activity occurring through your password (and related account access). You shall immediately notify Alimu if you suspect or become aware of any loss, theft, or unauthorized use of your password.
          Purchases through the Website
          All purchases made through the Website are subject to our acceptance. This means that we may refuse to accept or may cancel any transaction, in our sole discretion, and without liability to you or any third party. The Website does not permit orders from dealers, wholesalers, or other customers who intend to resell items offered on the Website. Alimu expressly conditions its acceptance of your order on your agreement to these Terms, and to all additional terms and conditions that are provided to you on the Website that govern your purchase of certain Products. By ordering Products through the Website, you agree to provide true, accurate, current, and complete information. Alimu reserves the right without prior notice to discontinue or change specifications and prices on Products offered on and outside of the site without incurring any obligation to you. Prices and availability are subject to change without prior notice, and Alimu reserves the right to revoke any offer to correct any errors, inaccuracies, or omissions.
          Alimu wants you to be satisfied with your purchases from this Website. If you wish to return a product, please review our Online Return Policy and Store Returns Policy, which are incorporated herein by reference.
          Website Ownership and Content
          The Website contains materials including, but not limited to, text, images, designs, photographs, videos, audio clips, graphics, button icons, …
        </p>
      </div>
      <!--  -->
      <div id="return Policy">
        <h3>Return policy</h3>
        <p>
          We do not provide cash refunds. Upon returning the item, you will receive credit in the form of a gift card that can be used online.
          All items returned must be in original condition (including all paperwork, packaging, and accessories) with tags still attached. All items must be unworn, unaltered, and unwashed. Items considered "Final Sale" cannot be returned or exchanged in store.
          When returning merchandise purchased online at a retail store, the store return policy will apply to your online purchase. We will accept exchanges within 30 days of the original purchase date with a valid receipt and all tags attached. The following items are not eligible for an in-store return:
        </p>
        <ul>
          <li>
            <a>Dresses</a>
          </li>
          <li>
            <a>Bodysuits</a>
          </li>
          <li>
            <a>Accessories</a>
          </li>
          <li>
            <a>Shoes</a>
          </li>
          <li>
            <a>Swim & Lingerie</a>
          </li>
          <li>
            <a>Beauty</a>
          </li>
          <li>
            <a>Sale Items</a>
          </li>
        </ul>
        <p>
          If you received items ordered online that were received damaged, defective, or incorrect, you will need to contact us via our Help Email portal in order to receive a full refund to your original payment method.
          Reserved Rights Regarding Returns:
          We reserve the right to solely define and limit, refuse, and/or reject returns from customers at any time due to:
          <br />* An irregular or excessive returns history indicative of "wardrobing;"
          <br />* An irregular or excessive returns history involving worn, altered, laundered, damaged, or missing items; or
          <br />* Potential fraudulent or criminal activity.
        </p>
        <p>
          Similarly, we reserve the right to refuse service (both in-store and online) to any customer or entity, due to similar actions as noted above.
          Alimu items sent to our Distribution Center will be discarded upon receipt.
        </p>

        <h5>What items are final sale?</h5>
        <p>All items ending in $.00, .96, .97, and .98, bodysuits, swimwear, undergarments, beauty products, cosmetics, accessories, and "Party Wear" are considered final sale, non-returnable and cannot be returned for store credit.</p>
        <h5>Can I purchase a return shipping label?</h5>
        <p>
          Yes! Alimu offers return shipping labels for Australian customers. Using return shipping labels is the recommended way to return your item to Alimu, as you will be able to track your return and receive your credit much more quickly than if you were to send your return back yourself.
          Return shipping labels can be purchased for $3.99 if you are returning a single item or for $7.99 if you are returning multiple items. Return shipping labels can be purchased and all returns can be submitted in our Help email.
        </p>
        <h5>Can I exchange items?</h5>
        <p>We do not offer exchanges but we welcome you to return your item(s) by mail in accordance with our Return Policy by using our online Help email and repurchasing any available item(s) on our site.</p>
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>
.legalContainer {
  padding: 10px 10%;
  min-height: 100vh;
  display: flex;
  gap: 50px;
  background-color: #d4d4d456;
  @media (max-width: 768px) {
    padding: 10px 5%;
  }
  p {
    text-align: justify;
  }
  .nav {
    flex: 0 0 25%;
    position: relative;
    @media (max-width: 768px) {
      display: none;
    }
    margin: 10px;
    background-color: #c7c7c7c7;

    box-sizing: border-box;
    ul {
      list-style-type: none;
      width: 100%;
      padding: 20px;
      margin: 0;
      background-color: #c7c7c7c7;
      height: 100vh;
      position: absolute;

      li {
        padding: 10px 20px;
        margin: 5px 0;
        background-color: rgb(223, 223, 223);
        &:hover {
          background-color: rgba(223, 223, 223, 0.589);
          cursor: pointer;
        }
      }
    }
  }
  //
  .contents {
    &__terms {
      margin-bottom: 100px;
    }
  }
}
</style>