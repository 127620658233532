import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import data from "../../data/data.js";

export default {
  namespaced: true,

  state() {
    return {
      cartItemsNumber: 0,
      shopItems: data,
      sideMenu: false,
      previewItem: {},
      cartItems: [],
      payToken: null
      //   cartPriceTotal: 0,
      //   selectedItem: "Jello",
      //   currency: { type: "USD", value: 1 },
      //   storeProducts: []
    };
  },

  mutations,
  actions,
  getters
};
