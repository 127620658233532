<template>
  <div>
    <ul v-if="getMenuState">
      <li class="store">
        <router-link to="/store" @click="toggleMenu">store </router-link>
      </li>
      <li>
        <router-link to="/" @click="toggleMenu">Home </router-link>
      </li>
      <li>
        <router-link to="/about">about</router-link>
      </li>
      <li>
        <router-link to="/help">conact us </router-link>
      </li>
      <li>
        <router-link to="/legal">legal</router-link>
      </li>
      <li>
        <a>support</a>
      </li>
      <!-- <i @click="toggleMenu" class="fa-solid fa-circle-xmark"></i> -->
    </ul>
  </div>
</template>


<script>
export default {
  data() {
    return {
      openClose: Boolean,
    };
  },

  computed: {
    getMenuState() {
      return this.$store.getters["shop/getCurrentMenuState"];
    },
  },
  methods: {
    toggleMenu() {
      this.$store.dispatch("shop/toggleSideNav", false); // close on link click
      this.openClose = this.getMenuState;
      //   console.log(this.getMenuState);
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: none;
  background: rgba(255, 255, 255, 0.95);
  width: 50vw;
  position: fixed;
  z-index: 1;
  top: 50px;
  margin: 0;
  padding: 0;

  .store {
  }

  i {
    position: absolute;
    top: 30px;
    right: 35px;
    color: rgb(10, 10, 10);
    font-size: 40px;
  }

  li {
    padding: 40px 30px;
    border-bottom: solid 2px rgb(190, 190, 190);

    a {
      //   text-decoration: none;
      color: rgb(0, 0, 0);
      z-index: 2;
      text-transform: uppercase;
    }
  }
}
</style>