const item = [
  //
  {
    name: "Puff sleeve dress",
    description: " Modest and vintage Straight style Sustainable and washable",
    materials: ["polyester", "cotton", "poly cotton ", "Computer knitted "],
    washing: [
      "Fashion washable ",
      "No bleaching ",
      "Tumble dryer",
      "Dry cleanable"
    ],
    colors: ["Red", "Blue", "Green"],
    sizes: ["S", "M", "L", "XL", "XXL"],
    quantity: 1,
    price: "60",
    selectedSize: "",
    selectedColor: "",
    exchangePrice: 0,
    imgLink: "almu items pictures/puff sleeve dress (2).jpg",
    code: "Puffsleevedress",
    sku: ""

    // name:'',
  },
  //
  {
    name: "Alimu Tee Men",
    description:
      "Anti-wrinkle, Anti-pilling, Breathable,Sustainable, Crew neck,Short sleeve, Men",
    materials: ["100% cotton", "cotton", "Canvas", "Knitted"],
    washing: [
      "Hand and machine washable ",
      "Opt for hand washed ",
      "Can tumble dry ",
      "Sun dried"
    ],
    colors: ["Black", "white"],
    sizes: ["S", "M", "L", "XL", "XXL", "XXXL"],
    quantity: 1,
    price: "25",
    selectedSize: "",
    selectedColor: "",
    exchangePrice: 0,
    imgLink: "almu items pictures/Alimu me Tees (3).jpg",

    code: "AlimuTeeMan",
    sku: ""

    // name:'',
  },

  {
    name: "Ture set",
    description:
      "Knitwear Two pieceWide leg Solid colour Shoulder padding Comfotable as it is stylish ",
    materials: ["Spandex / Cotton", "Computer knitted "],
    washing: ["Fashion washable ", "No bleaching ", "Tumble dryer  "],
    colors: ["Black", "Pink", "Green", "#AB9370", "Yellow"],
    sizes: ["S", "M", "L", "XL", "XXL"],
    quantity: 1,
    price: "54.45",
    selectedSize: "",
    selectedColor: "",
    exchangePrice: 0,
    imgLink: "almu items pictures/tureset black (2).jpg",
    code: "Tureset",
    sku: ""

    // name:'',
  },

  {
    name: "Puffer Jacket",
    description: " 51% white duck down",
    materials: ["Spandex / Cotton", "Computer knitted "],
    washing: [
      "Fashion washable ",
      "No bleaching ",
      "Hang dry",
      "Hand wash cold Machine washable"
    ],
    colors: ["Black", "white", "#AB9370", "#9E9D24"],
    sizes: ["S", "M", "L"],
    quantity: 1,
    price: "34.95",
    selectedSize: "",
    selectedColor: "",
    exchangePrice: 0,
    imgLink: "almu items pictures/puffer jacket (1).jpg",
    code: "PufferJacket",
    sku: ""

    // name:'',
  }

  //
];
export default item;
