<template>
  <div class="shopContainer">
    <div v-if="loading" class="lds-ripple">
      <div></div>
      <div></div>
    </div>
    <div v-show="!loading" class="itemBox" v-for="item in getdata" :key="item.code">
      <div class="imageBox">
        <router-link :to="'/store/preview/' + item.code">
          <img :src="desktop+item.imgLink" :alt="item.name" />
        </router-link>
      </div>
      <div class="textDescription">
        <h2>{{ item.name }}</h2>
        <div class="colorsContainer">
          <div
            v-for="color in item.colors"
            :key="color"
            class="colors"
            v-bind:style="{ backgroundColor: color }"
          ></div>
        </div>

        <p>$ {{ item.price }}</p>
      </div>
    </div>
    <!-- <div class="pagination">
      <ul>
        <li>1</li>
        <li>2</li>
        <li>3</li>
      </ul>
    </div>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemsData: [],
      desktop: "https://img.imageboss.me/alimupic/cover/400x600/",
      loading: true
    };
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 500);
  },
  computed: {
    getdata() {
      return this.$store.getters["shop/getItems"];
      // return null;
    }
  },
  methods: {
    storedata() {}
  }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Merienda&display=swap");
.shopContainer {
  position: relative;
  padding: 0px 10%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  min-height: 100vh;
  background-color: rgba(212, 212, 212, 0.336);
  @media (max-width: 500px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    padding: 0px;
  }

  @media (min-width: 500px) and(max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    padding: 0px;
  }
  @media (min-width: 1024px) and(max-width: 1080px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    padding: 0 5%;
  }

  .itemBox {
    //   background-color: black;
    box-sizing: border-box;
    gap: 10px;
    // margin: 2px;
  }

  .imageBox {
    //   border: solid 2px black;
    border: solid 2px rgba(192, 192, 192, 0.301);

    height: 26vw;

    @media (max-width: 500px) {
      height: 220px;
    }
    @media (min-width: 500px) and(max-width: 768px) {
      height: 300px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .textDescription {
    background-color: white;
    height: 80px;
    padding: 5px 0;
    @media (max-width: 500px) {
      height: 100px;
    }

    @media (min-width: 1024px) and(max-width: 1080px) {
      height: 110px;
    }
    h2 {
      font-size: 18px;
      text-align: center;
      margin: 0;
      margin-bottom: 5px;
      // font-family: "Merienda", cursive;
      font-family: "Roboto", sans-serif;
    }

    .colorsContainer {
      display: flex;
      // grid-template-columns: repeat(12, 1fr);
      position: relative;
      justify-content: center;
      border-radius: 50%;
      margin: 0 2px;
      // transform: translate(-50%, 0%);
      .colors {
        // position: absolute;

        border: solid 2px rgba(0, 0, 0, 0.326);
        margin: 3px;
        border-radius: 50%;
        height: 15px;
        width: 15px;
      }
    }

    p {
      text-align: center;
      margin: 0;
      font-weight: 600;
      font-size: 20px;
      font-family: "Merienda", cursive;
    }
  }

  .pagination {
    ul {
      list-style-type: none;
      display: flex;
      justify-content: center;
      height: 100px;
      position: relative;
      top: 20px;
      left: 60px;
      li {
        background-color: black;
        margin: 5px;
        border-radius: 50%;
        // padding: 5px;
        height: 20px;
        width: 20px;
        color: white;
        text-align: center;
      }
    }
  }
}

//@at-root

//loading
.lds-ripple {
  display: inline-block;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  // background-color: black;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid rgb(43, 7, 7);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

//loading
</style>
