import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDHINE9WKoXyh8GMBk8P9OvrXbFCj2XiOo",
  authDomain: "alimu22.firebaseapp.com",
  projectId: "alimu22",
  storageBucket: "alimu22.appspot.com",
  messagingSenderId: "92693654009",
  appId: "1:92693654009:web:f5cd7577309fda20817528",
  measurementId: "G-0W3LTYV581"
};
// apiKey: "AIzaSyB1EFl20IfwdbpG7plEAOtSNDAa7PpdcZU",
// authDomain: "alimuonline.firebaseapp.com",
// projectId: "alimuonline",
// storageBucket: "alimuonline.appspot.com",
// messagingSenderId: "43242817143",
// appId: "1:43242817143:web:6b7ca76ae0cb854c62a872",
// measurementId: "G-0SF7GE980R"

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const db = getFirestore(firebaseApp);

/*
// console.log(firebaseApp); 
// console.log(db);
// console.log(analytics);
*/

export { firebaseApp, db, analytics };
