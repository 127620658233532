export default {
  toggleSideNav(context, payload) {
    context.commit("OpenCloseSideNav", payload);
  },
  getPreviewItem(context, payload) {
    context.commit("getPreviewItem", payload);
  },
  addToCartx(context, payload) {
    context.commit("addToCart", payload);
  },
  deleteItem(context, sku) {
    context.commit("removeItem", sku);
  },
  getItems(context) {
    context.commit("getItems");
  },
  renewToken(context) {
    context.commit("updatePayAccessToken");
  },
  retrieveToken(context) {
    context.commit("getSavedToken");
    // console.log("triggered retrive saved token");
  },
  getRecipt(context, payload) {
    context.commit("getRecipt", payload);
    // console.log("triigered action");
  },
  clearCart(context) {
    context.commit("clearMyCart");
  }
};
