<template>
  <div class="footerContainer">
    <div class="brand">Alimu fashion</div>
    <div>
      <ul>
        <li>
          <a href="/about">about</a>
        </li>
        <li>
          <a href="/help">company</a>
        </li>
        <li>
          <router-link to="/legal">policy</router-link>
        </li>
      </ul>
    </div>
    <div class="socials">
      <div style="display:flex">
        <div>
          <a target="blank" href="https://www.facebook.com/Alimuofficial/">
            <i class="fa-brands fa-facebook"></i>
          </a>
          <a target="blank" href="https://www.instagram.com/alimuofficial/?hl=en">
            <i class="fa-brands fa-instagram"></i>
          </a>
          <a target="blank" href="https://youtube.com/channel/UC-hI8OXnJju8V1TGE4tNGYg">
            <i class="fa-brands fa-youtube"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="subFooter">
      <p>&copy;Copyright Alimu 2022 all rights reserved</p>
    </div>
  </div>
</template>



<style lang="scss" scoped>
.footerContainer {
  // background-color: #202b24;
  background-color: #000000c7;
  // background-color: #7c7c7c;
  position: relative;

  @media (max-width: 600px) {
    height: 50vh;
    .brand {
      display: none;
    }
  }
  .brand {
    top: 50%;
    color: rgb(65, 65, 65);
    position: Absolute;
    left: 10%;
    font-size: 3rem;
    font-family: "Roboto", sans-serif;
    font-weight: 100;
  }
  height: 30vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  // border-top: solid 2px #aca107;
  // border-bottom: solid 2px #aca107;
}

.subFooter {
  background-color: rgb(218, 218, 218);
  padding: 0 10%;
  p {
    // padding: 8px;
    margin: 0;
    @media (max-width: 500px) {
      text-align: center;
    }
    text-align: right;
  }
}

.socials {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 0 4%;
  gap: 10px;
  // bottom: 30px;
  color: rgb(177, 177, 177);
  // color: rgb(177, 177, 177);
  top: 10px;
  div {
    display: flex;
    justify-content: center;
    i {
      font-size: 40px;
      color: white;
      margin: 10px;
    }
  }
}
ul {
  position: relative;
  list-style-type: none;
  // height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  top: 40px;

  li {
    padding: 10px 20px;
    a {
      text-decoration: none;
      color: rgb(245, 245, 245);
      font-family: "Roboto", sans-serif;
    }
  }
}
</style>